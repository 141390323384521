/* eslint no-extend-native: 0 */
// core-js comes with Next.js. So, you can import it like below
/* eslint-disable */
import includes from 'core-js/library/fn/string/virtual/includes';
import repeat from 'core-js/library/fn/string/virtual/repeat';
import assign from 'core-js/library/fn/object/assign';
import 'core-js/es6/map';
import 'core-js/es6/set';
/* eslint-enable */

// Add your polyfills
// This files runs at the very beginning (even before React and Next.js core)
console.log('Load your polyfills');

String.prototype.includes = includes;
String.prototype.repeat = repeat;
Object.assign = assign;

/**
 * 检查浏览器版本
 */
const { userAgent } = navigator;
const invalidIE = userAgent.indexOf('MSIE') > -1;
console.log(userAgent);
// ie < 11
if (invalidIE) {
  window.location.href = '/versionlow.html';
}
